import React, { Suspense, useEffect, useState } from "react";
import { ConfigProvider, Modal, Spin } from "antd";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "antd/es/locale/de_DE";
import "antd/es/locale/it_IT";
import "antd/es/locale/pt_PT";
import "antd/es/locale/fr_FR";

import deDE from "antd/es/locale/de_DE";
import itIT from "antd/es/locale/it_IT";
import ptPt from "antd/es/locale/pt_PT";
import frFr from "antd/es/locale/fr_FR";

import defaultLocale from "antd/es/locale/en_US";
import { useTranslation } from "react-i18next";
import AppRouter from "./AppRouter";

import Sidebar from "./Sidebar";
import useApp from "../../hooks/useApp";
import styled from "styled-components";
import { AntOverride } from "./antOverride";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import ErrorBoundary from "../../ErrorBoundary";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Login from "../../Auth/Login";
import PasswordResetFinish from "../../Auth/PasswordResetFinish";
import PasswordResetInit from "../../Auth/PasswordResetInit";
import fetchIntercept from "fetch-intercept";
import { fetchEGate } from "../../helpers/eGateApi";
import { useEffectOnce, useToggle } from "react-use";
import { AuthorizeWebResponse, ServerInfoDto } from "../../eGate-API";
import { toJson } from "../../helpers/apiHelpers";
import * as Sentry from "@sentry/browser";
import useHotkeyShortcuts from "../../hooks/useHotkeyShortcuts";
import FullPageSpinnerStyler from "../../FullPageSpinnerStyler";
import classNames from "classnames";
import useServerInfo from "../../hooks/useServerInfo";
import { getDefaultView } from "../../helpers/utils";
import upperFirst from "lodash-es/upperFirst";
import { appVersion } from "../../version";

let timeout: any = null;
const sentryUrl = "https://28bb0b9063a04ac5aa668df46876c95b@o347419.ingest.sentry.io/2122812";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);

document.addEventListener("keydown", function (zEvent) {
  if (zEvent.ctrlKey && zEvent.key === "i") {
    try {
      console.log("SENTRY CHECK");
      throw new Error("SENTRY CHECK");
    } catch (e) {
      Sentry.captureException(e);
    }
  }
});

const Style = styled.div`
  width: var(--content-width);

  .collapsed {
    width: var(--content-width-collapsed);
  }
`;

const _15_Min = 900500; // in ms

function initSentry(serverInfo: ServerInfoDto) {
  const environment = upperFirst(serverInfo?.deployment);
  Sentry.init({
    enabled: serverInfo.version != "1.0.0.0",
    dsn: sentryUrl,
    release: `egate-digi@${appVersion}`,
    environment,
    attachStacktrace: true,
    beforeSend(event, hint) {
      const error = hint.originalException;
      //@ts-ignore
      if (error && error.name === "AbortError") {
        // Discard aborted requests
        return null;
      }
      return event;
    },
  });

  return serverInfo;
}

export function App() {
  const { i18n } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const { isFetching: serverInfoFetching } = useServerInfo();
  const { appData, login, onAfterLogout, resetApp } = useApp();

  const isUserLoggedIn = appData?.auth?.user;
  const isCollapsed = appData?.collapsed;
  const { xs, sm, md } = useBreakpoint();
  const matches = xs || sm || md;
  const hiddenMargin = "80px";
  const collapsedMargin = matches ? hiddenMargin : "80px";
  const mobileMargin = matches ? "240px" : "80px";
  const marginLeft = isCollapsed ? collapsedMargin : mobileMargin;

  useHotkeyShortcuts();

  fetchIntercept.register({
    request(url, config) {
      // Modify the url or config here
      return [url, config];
    },

    requestError(error) {
      // Called when an error occured during another 'request' interceptor call
      return Promise.reject(error);
    },

    response(response) {
      if (!response.url.includes("authorize/login") && !response.url.includes("authorize/link")) {
        if (response.status === 401) {
          void onAfterLogout();
          navigate("/signin", { replace: true, state: location.pathname });

          return response;
        }
        clearTimeout(timeout);
        //automatic logout
        timeout = setTimeout(async () => {
          const timeoutResponse = await fetchEGate("GET", "authorize/login");
          if (timeoutResponse.status === 404) {
            await onAfterLogout();
            navigate("/signin", { replace: true, state: location.pathname });
          }
        }, _15_Min);
      }

      // Modify the reponse object
      return response;
    },

    responseError(error) {
      // Handle an fetch error
      return Promise.reject(error);
    },
  });

  const [authPending, setAuthPending] = useToggle(true);

  async function callVerify() {
    try {
      const response: AuthorizeWebResponse = await toJson<AuthorizeWebResponse>(fetchEGate("GET", "authorize/login"));
      const re = new RegExp("^/reset-password");
      const isResetHistory = location?.pathname !== null ? re.exec(location?.pathname) !== null : false;
      if (response.user) {
        if (!isResetHistory && location.pathname == "/signin") {
          const view = getDefaultView(response);
          navigate(view ?? "/app/dashboard", { replace: true });
        }
        login(response);
      } else {
        if (!isResetHistory && location.pathname !== "/signin") {
          resetApp();

          navigate("/signin", { replace: true, state: location.pathname });
        }
      }
    } catch (e) {
      console.error(e);
    }

    setAuthPending(false);
  }

  useEffectOnce(() => {
    if (!isUserLoggedIn) {
      void callVerify();
    }
  });

  useEffect(() => {
    Modal.destroyAll();
  }, [location.pathname]);

  function getLocale() {
    switch (i18n.language) {
      case "en":
        return defaultLocale;
      case "de":
        return deDE;
      case "it":
        return itIT;
      case "pt":
        return ptPt;
      case "fr":
        return frFr;
    }
  }

  const { useGetView } = useApp();
  const mobile = useGetView().isMobile;
  const serverInfo = useServerInfo();

  const [sentryInitiated, setSentryInitiated] = useState(false);

  useEffect(() => {
    if (serverInfo.data && !sentryInitiated) {
      initSentry(serverInfo.data);
      setSentryInitiated(true);
    }
  }, [serverInfo.data?.deployment, serverInfo.data?.version]);

  if (!isUserLoggedIn && authPending && serverInfoFetching && !serverInfo) {
    return <div />;
  }

  const isApp = window.location.pathname.includes("app");

  return (
    <ErrorBoundary>
      <GoogleOAuthProvider clientId={serverInfo?.data?.googleClientId as string}>
        <ConfigProvider
          locale={getLocale()}
          theme={{
            token: {
              controlOutline: "transparent",
              colorErrorOutline: "transparent",
              colorWarningOutline: "transparent",
              controlOutlineWidth: 0,
              colorPrimary: "#27CA7C",
              borderRadius: 10,
              colorLink: "#03AF5C",
              colorText: "#000",
              colorTextSecondary: "#000",
              colorIcon: "#999",
              fontSize: 15,
              controlHeight: 30,
              colorLinkHover: "#03AF5C",
              colorLinkActive: "#03AF5C",
              colorSuccess: "#27CA7C",
              colorWarning: "#E4A915",
              motion: false,
            },
            components: {
              Tabs: { paddingContentHorizontalLG: 8 },
              Table: {
                fontSizeSM: 12,
                paddingContentVerticalSM: 6,
                paddingContentHorizontalSM: 5,
                fontWeightStrong: 500,
                fontSize: 14,
              },
              Empty: { margin: 0 },
              Button: {
                paddingContentHorizontal: 12,
                fontWeightStrong: 500,
                colorError: "#f16d43",
                controlHeight: 30,
                colorPrimaryBg: "#03AF5C",
              },
              Menu: { paddingContentHorizontal: 16, colorBgBase: "rgba(0, 175, 91, 0.5)", itemMarginInline: 0 },
              List: { paddingContentHorizontalSM: 0, paddingContentHorizontal: 0, paddingContentVerticalSM: 2 },
              Image: { zIndexBase: 2001 },
              Modal: { zIndexBase: 1020, colorPrimary: "#03AF5C" },
              Notification: { colorPrimary: "#03AF5C" },
              Select: { fontSize: 13, controlHeight: 30 },
              Upload: {
                padding: 0,
              },
              Alert: { defaultPadding: "2px 10px" },
            },
          }}
        >
          {isApp && (
            <>
              <AntOverride />
              {!mobile && <Sidebar />}{" "}
            </>
          )}

          <Suspense
            fallback={
              <FullPageSpinnerStyler className={"full-page-loader"}>
                <Spin spinning={false} />
              </FullPageSpinnerStyler>
            }
          >
            <Routes>
              <Route
                element={
                  <>
                    <Style
                      className={classNames("isoContentMainLayout", { "open": !isCollapsed })}
                      style={{
                        marginLeft: mobile ? "0" : marginLeft,
                      }}
                    >
                      <AppRouter />
                    </Style>
                  </>
                }
                path={"/app/*"}
              />
              <Route element={<PasswordResetFinish />} path={"/reset-password/:token"} />
              <Route element={<PasswordResetInit />} path={"/reset-password/"} />
              <Route element={<Login />} path={"/signin"} />
              <Route element={<Login />} path={"/"} />
            </Routes>
          </Suspense>
        </ConfigProvider>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  );
}

export default App;
