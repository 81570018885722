import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { AuthorizeWebResponse, CustomerListDto, CustomerWithTimezoneListDto } from "../eGate-API";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-use";
import { ColumnMeta } from "../components/ColumnChooser";
import { useLocation } from "react-router-dom";
import { resolveLanguage } from "../i18n";
import { useFavourites } from "./userFavourites";

const queryKey = ["auth"];

export interface Preferences {
  id: string;
  transactionVisibleColumns?: Record<string, ColumnMeta>;
  fillContainersVisibleColumns?: Record<string, ColumnMeta>;
}

export interface Favourites {
  id: string;
  sidebarFavourites: string[];
  selectedCustomer?: Partial<CustomerWithTimezoneListDto>;
  version: number;
}

export interface AppState {
  auth?: AuthorizeWebResponse;
  collapsed: boolean;
  menuOpened?: boolean;
  menuFocused?: boolean;
  isModalOpened: boolean;
  preferences?: Preferences;
  selectedCustomer?: Partial<CustomerListDto>;
}

export default function useApp() {
  const queryClient = useQueryClient();
  const { i18n } = useTranslation();

  function getData(): AppState | undefined {
    return queryClient.getQueryData(queryKey);
  }

  function setData(data: Partial<AppState>) {
    const appState: AppState | undefined = getData();
    queryClient.setQueryData(queryKey, { ...appState, ...data });
  }

  function toggleCollapsed(c?: boolean) {
    const appState = getData();
    setData({ collapsed: c ?? !appState?.collapsed });
  }

  function toggleMenuOpened(c?: boolean) {
    const appState = getData();
    setData({ menuOpened: c ?? !appState?.menuOpened });
  }

  function toggleModalOpened() {
    const appState = getData();
    setData({ isModalOpened: !appState?.isModalOpened });
  }

  function toggleMenuFocused(menuFocused: boolean) {
    setData({ menuFocused });
  }

  function useGetView() {
    const isDesktopOrLaptop = useMedia("(min-device-width: 1224px)");
    const isBigScreen = useMedia("(min-device-width: 1824px)");
    const isMiddle = useMedia("(max-width: 996px)");
    const isMobile = useMedia("(max-width: 768px)");
    const isPortrait = useMedia("(orientation: portrait)");
    const isRetina = useMedia("(min-resolution: 2dppx)");

    return {
      isDesktopOrLaptop,
      isBigScreen,
      isMobile,
      isMiddle,
      isPortrait,
      isRetina,
    };
  }
  async function resetApp() {
    await queryClient.clear();
    await queryClient.refetchQueries();
  }

  function login(auth: AuthorizeWebResponse) {
    setData({
      auth,
      preferences: data?.preferences ? JSON.parse(auth?.preferences) : undefined,
    });

    if (auth.language) {
      void i18n.changeLanguage(auth.language);
    }
  }

  async function logout() {
    const lang = Intl.DateTimeFormat().resolvedOptions().locale;
    const language = resolveLanguage(lang as any);
    document.documentElement.lang = language;
    i18n.changeLanguage(language);

    await resetApp();
  }

  const { data, isFetched } = useQuery<{
    collapsed: boolean;
    auth?: AuthorizeWebResponse;
    isModalOpened: boolean;
    menuOpened?: boolean;
    menuFocused?: boolean;
    selectedCustomer?: Partial<CustomerWithTimezoneListDto>;
    preferences?: Preferences;
  }>({
    queryKey: queryKey,
    initialData: { collapsed: false, auth: undefined, isModalOpened: false, menuOpened: false },
  });

  const [favourites] = useFavourites({ enabled: !!data.auth?.id });
  const selectedCustomer = favourites?.selectedCustomer;

  return {
    login,
    appData: {
      ...data,
      selectedCustomer,
    },
    isLoginFetched: isFetched,
    onAfterLogout: logout,
    resetApp,
    toggleCollapsed,
    toggleMenuOpened,
    toggleModalOpened,
    useGetView,
    toggleMenuFocused,
    setData,
  };
}
